<template>
  <div>We are lemac.</div>
</template>

<script>
export default {
  name: 'HomeAbout',
  components: {},
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>
